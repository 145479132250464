import React, { useEffect, useState } from "react";
import { Root } from "~/src/components/_root/index";
import { ORG_NAME, BACKEND_URL } from "~src/config/env";
import { splitKeyValue } from "~/src/util/splitKeyValue";
import classNames from "classnames";
import i18n from "./i18n"; // Import i18n instance
import { SourcesProvider } from "~src/context/SourcesContext";
import { client } from "../util/api/index";
import { Suggestion, BackendInfo } from "../util/suggestions/suggestions";

import "./styles.scss";

interface CityGPTProps {
  lang: string;
}

export const StyleReset: React.FC<CityGPTProps> = ({ lang }) => {
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem("uID") || sessionStorage.getItem("uID"),
  );
  const [cID, setCId] = useState<string | null>(null);
  const [preferences, setPreferences] = useState<BackendInfo | null>(null);
  const [preferencesError, setPreferencesError] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[] | null>(null);
  const [suggestionsError, setSuggestionsError] = useState<boolean>(false);
  const [startupFinished, setStartupFinished] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    fetch(`${BACKEND_URL}/log`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        referrer: document.referrer || null,
        initialQuery: null,
        uID: userId,
      }),
    })
      .then(async (response) => {
        const user = await response.json();
        if (user && user.id) {
          localStorage.setItem("uID", user.id);
          setUserId(user.id);
          setCId(user.cID);
        }
      })
      .catch((error) => {
        console.error("Error while fetching logging id", error);
      });
  }, [userId]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/info?source=${lang}:preferences`,
        );
        if (response.ok) {
          const data = await response.json();
          setPreferences(data);
        } else {
          setPreferencesError(true);
        }
      } catch (error) {
        console.error("Error fetching preferences", error);
        setPreferencesError(true);
      }
    };

    fetchPreferences();
  }, [lang]);

  useEffect(() => {
    client
      .getPredefinedQuestions({
        url: window.location.pathname || "/",
        language: lang,
      })
      .then((data) => {
        setSuggestions(data as Suggestion[]);
      })
      .catch((error) => {
        console.error("Error fetching suggestions", error);
        setSuggestions([]);
        setSuggestionsError(true);
      });
  }, [lang]);

  // Now we can use the preferences and suggestions to set up the i18n instance
  useEffect(() => {
    if (preferences && suggestions && lang) {
      const translations = preferences?.autocomplete
        ?.filter((item) => item.value.startsWith("translation"))
        .reduce((acc: Record<string, any>, item) => {
          const [key, value] = splitKeyValue(item.value);
          const keys = key.split(".");
          keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value;
            } else {
              acc[key] = acc[key] || {};
            }
            return acc[key];
          }, acc);
          return acc;
        }, {});

      i18n.addResourceBundle(lang, "translation", {
        ...(translations?.translation || {}),
        chat: {
          ...(translations?.translation?.chat || {}),
          suggestions,
        },
      });

      setStartupFinished(true);
    }
  }, [preferences, suggestions, lang]);

  useEffect(() => {
    if (startupFinished) {
      setFadeIn(true);
    }
  }, [startupFinished]);

  if (preferencesError) {
    return null; // Could handle error/loading states as needed but for now just return null
  }

  if (!startupFinished) {
    return null;
  }

  return (
    <div className={classNames([ORG_NAME, "chat-gpt"])}>
      <div
        className={classNames([
          "chat-gpt__startup",
          { ["chat-gpt__startup--finished"]: fadeIn },
        ])}
      >
        <SourcesProvider>
          <Root lang={lang} userId={userId} cID={cID} />
        </SourcesProvider>
        ,
      </div>
    </div>
  );
};

export default StyleReset;
