import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LinkedText } from "~/src/components/LinkedText/index";
import IconCross from "jsx:~/src/components/_root/assets/cross.svg";
import ExpandDimension from "~/src/components/ExpandDimension/index";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface MainButtonProps {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  inputRef: React.RefObject<HTMLInputElement>;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MainButton: React.FC<MainButtonProps> = ({
  expanded,
  setExpanded,
  closeModal,
  inputRef,
}) => {
  const [showIndication, setShowIndication] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 200
      ) {
        setShowIndication(true);
      } else {
        setShowIndication(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setExpanded(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="main-button">
      <button
        className={classNames([
          "expand-dimension__group-hover",
          "main-button__expand",
        ])}
        onClick={handleClick}
      >
        <div className="main-button__icon"></div>
        <div
          className={classNames(
            "main-button__text-wrapper",
            showIndication && "main-button__text-wrapper--show",
          )}
        >
          <ExpandDimension forceExpanded={expanded || showIndication}>
            <div
              className={classNames("main-button__text", {
                "main-button__text--hidden": expanded,
              })}
            >
              <LinkedText markdown={t("mainButton.hello")} />
              <div className="main-button__spacing">
                <div className="button button--primary">
                  <span>{t("mainButton.buttonText")}</span>
                </div>
              </div>
            </div>
          </ExpandDimension>
        </div>
      </button>
      <button
        className={classNames("main-button__close", {
          "main-button__close--expanded": expanded,
        })}
        onClick={closeModal}
      >
        <div className="main-button__close-icon">
          <IconCross />
        </div>
      </button>
    </div>
  );
};
