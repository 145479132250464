import React, { useState, useCallback, useEffect } from "react";
import { EventSourceMessage, useEventSource } from "./useEventSource";
import { PromptForm } from "~src/components/PromptForm/index";
import { Spinner } from "~/src/components/Spinner/index";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TypingMessageQueue } from "./TypingMessageQueue";
import { LinkedText, LinkedTextLine } from "~/src/components/LinkedText/index";
import Sources from "~src/components/Sources/index";
import { AccordionSection } from "~src/components/Accordion/index";
import Markdown from "~/src/components/Markdown/index";
import ExpandDimension from "~/src/components/ExpandDimension/index";
import { Feedback } from "~/src/components/Feedback/index";
import { Suggestion } from "../../util/suggestions/suggestions";
import { DEFAULT_SHORT_NAME, SourcesDto } from "./helpers";
import { API_KEY } from "~/src/config/env";
import { isProd } from "~/src/config/env";

import { useSources } from "~src/context/SourcesContext";

interface ChatProps {
  baseURL: string;
  lang: string;
  expanded: boolean;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>; // Add inputRef prop
  userId: string | null;
  cID: string | null;
}

const EVENT_SOURCE_NAME = "default";

const DEFAULT_MODEL = DEFAULT_SHORT_NAME.MODEL_4o;
const DEFAULT_OTHER_MODEL =
  DEFAULT_MODEL === DEFAULT_SHORT_NAME.MODEL_4o
    ? DEFAULT_SHORT_NAME.MODEL_4o_8k
    : DEFAULT_SHORT_NAME.MODEL_3_5_16k;

export const Chat: React.FC<ChatProps> = ({
  className,
  expanded,
  baseURL,
  lang,
  inputRef, // Use inputRef prop
  userId,
  cID,
}) => {
  const { t } = useTranslation();
  const { dispatch } = useSources(); // Access the dispatch function

  const resetSources = () => {
    dispatch({ type: "RESET_SOURCES" });
  };

  const addSource = (link: SourcesDto) => {
    dispatch({ type: "ADD_SOURCE", payload: link });
  };

  const [inputValue, setInputValue] = useState("");
  const [lastInputValue, setLastInputValue] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const [linksResult, setLinksResult] = useState<string>("");
  const [queryResult, setQueryResult] = useState<string>(
    /* data.queryResult || */ "",
  );
  const [maybeBadRating, setMaybeBadRating] = useState<boolean>(false);

  const [requestId, setRequestId] = useState<string | null>(null); // the id of the prompt with the answer
  // @ts-ignore
  const [erzFunction, setErzFunction] = useState<{
    query: string;
    function: string;
  } | null>(null);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [disabledTimeout, setDisabledTimeout] = useState<number | undefined>(
    undefined,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sseUri, setSseUri] = useState("");

  const { close: eventSourceClose, start: eventSourceStart } = useEventSource();

  const setNewEventSource = useCallback(
    (queryValue: string, model: string, ft?: string) => {
      if (queryValue && baseURL) {
        // the random part is just that we create a new event source when we submit...
        // maybe there are better solutions for this.
        const source = `${baseURL}/assistant/sse?query=${encodeURIComponent(
          queryValue,
        )}&referrer=${encodeURIComponent(
          window.location.pathname || "/",
        )}&key=${API_KEY}&r=${Math.floor((new Date().getTime() / 1000) % 600)}${userId ? `&uID=${userId}` : ""}${
          cID ? `&cID=${cID}` : ""
        }&lang=${lang}${model !== DEFAULT_MODEL ? `&modelId=${model}` : ""}`;

        setSseUri(source);
      }
    },
    [baseURL, lang],
  );

  const sendSubmit = (inputValue: string, model = DEFAULT_MODEL) => {
    if (inputValue.trim() === "") return;

    const fetchData = async () => {
      // setIsLoading(true);
      setErzFunction(null);
      resetSources();
      setNewEventSource(inputValue, model);
      setLinksResult("");
      setMaybeBadRating(false);
      // setQueryResult(t("answers.oneMoment"));
    };
    setSubmitDisabled(true);
    setInputValue("");
    setLastInputValue(inputValue);
    setQueryValue(inputValue);
    if (disabledTimeout) {
      clearTimeout(disabledTimeout);
    }
    setDisabledTimeout(
      window.setTimeout(() => {
        setSubmitDisabled(false);
      }, 5000),
    );

    fetchData();
  };

  const parseMessageFromEventSource = useCallback(
    (parsedData: Record<string, any>, queue: TypingMessageQueue) => {
      try {
        if (parsedData.links) {
          setLinksResult((linksResult) => {
            return linksResult.concat(parsedData.links);
          });
        }
        if (parsedData.usedLinks) {
          addSource(parsedData.usedLinks);
        }
        if (parsedData.maybeBadRating) {
          setMaybeBadRating(true);
        }
        if (parsedData.response) {
          const answerEnd = () => {
            setSubmitDisabled(false);
            setIsLoading(false);
          };
          if (parsedData.response === "__THIS_IS_THE_ANSWER_END__") {
            answerEnd();
            return;
          }

          if (parsedData.response === "__THIS_IS_THE_END__") {
            eventSourceClose(EVENT_SOURCE_NAME);
            queue.enqueue(parsedData.response);
            queue.setThisIsTheEnd();
            setQueryValue("");
            answerEnd();
            return;
          }
          if (parsedData.response === "__CLR__") {
            setSubmitDisabled(false);
            queue.enqueue(parsedData.response);
            return;
          }

          queue.enqueue(parsedData.response);
          /*setQueryResult(queryResult => {
                return queryResult.concat(parsedData.response)
              })*/
        }
        if (parsedData.erz) {
          setErzFunction({
            query: queryValue,
            function: JSON.stringify(parsedData.erz),
          });
        }
        if (parsedData.timetable) {
          setErzFunction({
            query: queryValue,
            function: JSON.stringify(parsedData.timetable),
          });
        }
        if (parsedData.id) {
          setRequestId(parsedData.id);
        }
        if (
          parsedData.model &&
          parsedData.model.model === DEFAULT_OTHER_MODEL
        ) {
          setSubmitDisabled(false);
          setQueryResult(`Wir versuchen's mit längerem Kontext...`);
          if (parsedData.model.query) {
            setQueryValue(parsedData.model.query);
            setNewEventSource(
              parsedData.model.query,
              parsedData.model.model,
              parsedData.model.ft,
            );
            setInputValue(parsedData.model.query);
          } else {
            setNewEventSource(
              queryValue,
              parsedData.model.model,
              parsedData.model.ft,
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [eventSourceClose, queryValue, setNewEventSource],
  );

  // after the close we reset the state to have a fresh chat when we open it again
  useEffect(() => {
    if (!expanded) {
      setIsLoading(false);
      setSubmitDisabled(false);

      setQueryValue("");
      setLastInputValue("");
      setInputValue("");

      // reset results if desired
      setQueryResult("");
      setLinksResult("");
      resetSources();
      setMaybeBadRating(false);

      // Optionally close any active EventSource connection
      eventSourceClose(EVENT_SOURCE_NAME);
    }
  }, [expanded, eventSourceClose]);

  useEffect(() => {
    let linksReceived = false;
    try {
      if (!sseUri || !queryValue) {
        return;
      }
      setIsLoading(true);
      setLinksResult("");
      resetSources();
      setMaybeBadRating(false);
      setQueryResult(t("chat.answer.oneMoment"));

      const queue = new TypingMessageQueue(setQueryResult);
      const onerror = (e: Event) => {
        // for some strange reason, we get an error event before the server sends the __THIS_IS_THE_END__ event
        // in some cases. We don't need to show that error, after we received some links... The main result is here anyway
        // For example: Ask for "Wer ist Inka?" on WintiGPT....
        if (!linksReceived) {
          setQueryResult((queryResult) =>
            queryResult.concat(`
  
    ${t("chat.answer.serverConnectionError")}`),
          );
        }
        console.log("SSE Error", e);
        eventSourceClose(EVENT_SOURCE_NAME);
        setQueryValue("");
        setSubmitDisabled(false);
        setIsLoading(false);
      };
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data);

        if (parsedData.links) {
          linksReceived = true;
        }
        parseMessageFromEventSource(parsedData, queue);
      };
      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror);

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME);
      };
    } catch (error) {
      console.log(error);
    }
  }, [
    eventSourceClose,
    eventSourceStart,
    parseMessageFromEventSource,
    queryValue,
    sseUri,
    t,
  ]);

  // Compute submitDisabled based on conditions
  const submitBlocked =
    inputValue.length === 0 ||
    inputValue === lastInputValue ||
    isLoading ||
    submitDisabled;

  // type guarded suggestions
  const result = t("chat.suggestions", { returnObjects: true });
  const suggestions: Suggestion[] = Array.isArray(result)
    ? (result as Suggestion[])
    : [];

  return (
    <div
      className={classNames("chat", className, {
        ["chat--expanded"]: expanded,
      })}
    >
      <div className="chat__content">
        <div className="chat__spacer">
          {!lastInputValue && (
            <>
              <h1>
                <LinkedTextLine markdown={t("mainButton.hello")} />
              </h1>

              <LinkedText className="chat__intro" markdown={t("chat.intro")} />

              {suggestions.map((suggestion: Suggestion) => (
                <AccordionSection
                  key={suggestion.question}
                  label={suggestion.label}
                  question={suggestion.question}
                  content={suggestion.content}
                  sendSubmit={sendSubmit}
                ></AccordionSection>
              ))}
            </>
          )}
          {lastInputValue && <h1>{lastInputValue}</h1>}

          <div className="chat__markdown">
            {queryResult && <Markdown markdown={queryResult}></Markdown>}
            {isLoading && (
              <div className="chat__spinner">
                <Spinner />
              </div>
            )}
          </div>
          <div>
            {isProd && !!lastInputValue && !isLoading && (
              <Feedback
                baseURL={baseURL}
                requestId={requestId}
                userId={userId}
                inputValue={lastInputValue}
                modules={["vote"]}
              />
            )}
            <Sources />
          </div>
          {!isProd && !!lastInputValue && !isLoading && (
            <Feedback
              baseURL={baseURL}
              requestId={requestId}
              userId={userId}
              inputValue={lastInputValue}
              modules={["classification", "form"]}
            />
          )}
        </div>
      </div>

      <div className="chat__footer">
        <div className="chat__spacer">
          <PromptForm
            ref={inputRef} // Pass the inputRef to PromptForm
            setInputValue={setInputValue}
            inputValue={inputValue}
            sendSubmit={sendSubmit}
            submitDisabled={submitBlocked}
            isLoading={isLoading}
          />
          <LinkedText className="chat__legal" markdown={t("chat.legal")} />
        </div>
      </div>
    </div>
  );
};
