import configHugcontactgpt from "../../clients/hugcontactgpt/config.js";
import configLozaerncitygpt from "../../clients/lozaerncitygpt/config.js";

export const ORG_NAME = process.env.ORG_NAME;
export const isLocal = process.env.NODE_ENV === "development";
export const isProd = process.env.ENVIRONMENT === "production";
export const ENV_SHORT = isProd ? "prod" : "dev";
export const HOST_POSTFIX = `.api.${ENV_SHORT}.genai.liip.ch`;

// if you wanna use the local proxy
// export const BACKEND_URL = isLocal
//   ? `/api/${ORG_NAME}`
//   : `https://${ORG_NAME}${HOST_POSTFIX}/${ORG_NAME}`;

export const BACKEND_URL = `https://${ORG_NAME}${HOST_POSTFIX}/${ORG_NAME}`;

export const PUBLISH_URL = isLocal
  ? ""
  : `https://${ORG_NAME}.chatbot-component.${ENV_SHORT}.genai.liip.ch`;

export let DEFAULT_LANGUAGE = null;
export let API_KEY = null;

switch (ORG_NAME) {
  case "hugcontactgpt": {
    DEFAULT_LANGUAGE = configHugcontactgpt.DEFAULT_LANGUAGE;
    API_KEY = configHugcontactgpt.API_KEY;
    break;
  }
  case "lozaerncitygpt": {
    DEFAULT_LANGUAGE = configLozaerncitygpt.DEFAULT_LANGUAGE;
    API_KEY = configLozaerncitygpt.API_KEY;
    break;
  }
}

// see
// deployment/ci/env.sh
// for the environment variables

export const STARTUP_MESSAGE = `
ChatGPT-Component
  ORG_NAME: ${process.env.ORG_NAME}
  TIMESTAMP: ${process.env.CI_COMMIT_TIMESTAMP}
  GIT: ${process.env.CI_COMMIT_SHORT_SHA}
  ENVIRONMENT: ${process.env.ENVIRONMENT}
`;
